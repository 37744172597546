<template>
  <v-layout>
    <v-dialog v-model="showModal" class="reference-creation" width="70%" >
      <v-card class="reference-creation__card">
        <v-card-title class="reference-creation__title">
          {{ whoDoWeCreate }}
        </v-card-title>
        <v-form
          v-show="!isLoading"
          ref="form"
          v-model="isValid"
          class="reference-creation__form"
          lazy-validation
          @submit.prevent="submit"
        >
          <v-select
            v-if="!firstStepPassed"
            v-model="select"
            :items="items"
            label="Тип организации"
            required

            @change="$refs.form.resetValidation()"
          />

          <v-card-title v-if="firstStepPassed">
            {{ select }}
          </v-card-title>

          <v-layout v-if="select === 'Юридическое лицо'" column>
            <v-text-field
              v-model="entity.inn"
              :rules="actor === 'receiver' ? [true] : validation.innEntity"
              label="ИНН"
              type="number"
              :required="actor !== 'receiver'"
              @blur="getData(entity.inn, 'Юридическое лицо')"
            />
            <v-text-field
              v-model="entity.kpp"
              :rules="actor === 'receiver' ? [true] : validation.kpp"
              label="КПП"
              type="number"
              :required="actor !== 'receiver'"
            />
          </v-layout>
          <v-layout v-if="select === 'Индивидуальный предприниматель'">
            <v-text-field
              v-model="entrepreneur.inn"
              :rules="validation.innIndividual"
              label="ИНН"
              type="number"
              :required="actor !== 'receiver'"
              @blur="
                getData(entrepreneur.inn, 'Индивидуальный предприниматель')
              "
            />
          </v-layout>

          <v-layout
            v-if="select === 'Физическое лицо' && firstStepPassed"
            column
          >
            <v-text-field
              v-model="individual.surname"
              label="Фамилия"
              required
              :rules="validation.noEmptyFields"
            />
            <v-text-field
              v-model="individual.name"
              label="Имя"
              required
              :rules="validation.noEmptyFields"
            />
            <v-text-field v-model="individual.patronymic" label="Отчество" />
            <v-text-field
              v-model="individual.inn"
              :rules="validation.innIndividual"
              label="ИНН"
              type="number"
            />
            <v-layout column>
              Паспортные данные
              <v-menu
                ref="menu"
                v-model="dateMenu"
                :close-on-content-click="false"
                :return-value.sync="individual.passport.issueDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="individual.passport.issueDate"
                    label="Дата выдачи"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="individual.passport.issueDate"
                  no-title
                  scrollable
                >
                  <v-spacer />
                  <v-btn text color="primary" @click="dateMenu = false">
                    Отмена
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(individual.passport.issueDate)"
                  >
                    ОК
                  </v-btn>
                </v-date-picker>
              </v-menu>
              <v-text-field
                v-model="individual.passport.series"
                label="Серия"
                required
                type="number"
                :rules="validation.passportSeries"
              />
              <v-text-field
                v-model="individual.passport.number"
                label="Номер паспорта"
                required
                type="number"
                :rules="validation.passportNumber"
              />

              <v-text-field
                v-model="individual.passport.whoIssued"
                label="Кем выдан"
                required
                :rules="validation.noEmptyFields"
              />
            </v-layout>
            <v-layout v-if="editData.action !== 'edit'" column>
              Контактные данные
              <v-text-field-simplemask
                v-model="individual.contact.contactcellphone"
                :label="'Мобильный телефон'"
                :properties="{
                  readonly: false,
                  disabled: false,
                  outlined: false,
                  clearable: true,
                  type: 'tel',
                  placeholder: 'Введите мобильный телефон телефон',
                }"
                :options="{
                  humanMask: '+# (###) ###-##-##',
                  machineMask: '############',
                  empty: null,
                  applyAfter: false,
                  alphanumeric: true,
                  lowerCase: false,
                }"
              />
              <v-text-field
                v-model="individual.contact.contactemail"
                label="Электронная почта"
              />
              <v-text-field
                v-model="individual.contact.contactadress"
                label="Адрес"
              />
            </v-layout>
          </v-layout>

          <v-layout
            v-else-if="select === 'Юридическое лицо' && firstStepPassed"
            column
          >
            <v-text-field
              v-model="entity.name"
              label="Наименование"
              :rules="validation.noEmptyFields"
              required
            />
            <v-text-field
              v-model="entity.fullName"
              label="Полное наименование организации"
              required
            />
            <v-text-field
              v-model="entity.bankAccount"
              label="Номер банковского счёта"
              :rules="actor === 'receiver' ? [true] : validation.bankAccount"
              type="number"
            />
            <v-text-field
              v-model="entity.bik"
              label="БИК"
              :rules="actor === 'receiver' ? [true] : validation.validation.bik"
              type="number"
              :required="actor !== 'receiver'"
            />
          </v-layout>

          <v-layout
            v-else-if="
              select === 'Индивидуальный предприниматель' && firstStepPassed
            "
            column
          >
            <v-text-field
              v-model="entrepreneur.fullName"
              label="Название"
              :rules="validation.noEmptyFields"
              required
            />
            <v-text-field
              v-model="entrepreneur.surname"
              label="Фамилия"
              :rules="validation.noEmptyFields"
              required
            />
            <v-text-field
              v-model="entrepreneur.name"
              label="Имя"
              :rules="validation.noEmptyFields"
              required
            />
            <v-text-field v-model="entrepreneur.patronymic" label="Отчество" />
            <v-text-field
              v-model="entrepreneur.bankAccount"
              label="Номер банковского счёта"
              :rules="validation.bankAccount"
              type="number"
            />
            <v-text-field
              v-model="entrepreneur.bik"
              label="БИК"
              :rules="validation.bik"
              type="number"
              required
            />
          </v-layout>

          <v-layout justify-space-between>
            <v-layout class="reference-creation__btn-wrapper">
              <v-btn
                v-if="firstStepPassed"
                class="reference-creation__btn"
                color="primary"
                @click="firstStepPassed = false"
              >
                Назад
              </v-btn>
              <v-btn
                v-if="!firstStepPassed"
                class="reference-creation__btn"
                @click="nextStep"
              >
                Далее
              </v-btn>
            </v-layout>

            <v-layout class="reference-creation__btn-wrapper" justify-end>
              <v-btn
                v-if="firstStepPassed"
                type="submit"
                class="reference-creation__btn"
                color="primary"
                :disabled="!isValid"
              >
                Сохранить
              </v-btn>
              <v-btn class="reference-creation__btn" @click="close">
                Отменить
              </v-btn>
            </v-layout>
          </v-layout>
        </v-form>
        <v-progress-circular
          v-if="isLoading"
          size="60"
          width="6"
          indeterminate
          class="reference-creation__progress"
          color="primary"
        />
      </v-card>
    </v-dialog>
    <v-dialog v-model="isOrgExist" width="40%">
      <v-card
        class="reference-creation__card flex-column d-flex justify-center align-center"
      >
        <v-card-title class="reference-creation__title mb-8">
          Данная организация уже присутсвует в списке
        </v-card-title>
        <v-btn
          class="reference-creation__btn"
          width="20%"
          color="primary"
          @click="isOrgExist = false"
        >
          Ок
        </v-btn>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import daData from '@/api/daData';
import SimpleMask from '@/components/SimpleMask.vue';

export default {
  components: {
    'v-text-field-simplemask': SimpleMask,
  },
  props: {
    isShowing: {
      type: Boolean,
      required: true,
    },
    editData: {
      type: [Object, String],
      required: false,
    },
    actor: {
      required: true,
    },
  },
  data() {
    return {
      dateMenu: false,
      entityRules: [(v) => v.length === 10 || 'Введите 10 цифр'],
      validation: {
        noEmptyFields: [(v) => !!v || 'Поле обязательно для заполнения'],
        // eslint-disable-next-line
        email: [(v)=> (/^([\d\w\.-]+@[A-Za-z]+\.[A-Za-z]+)$/).test(v)  || "Введите корректный email"],
        innIndividual: this.actor === 'receiver' ? [() => true] : [
          (v) => /^(\d{12})$/.test(v) || 'Введите корректный ИНН - 12 цифр',
        ],
        innEntity: this.actor === 'receiver' ? [() => true] : [
          (v) => /^(\d{10})$/.test(v) || 'Введите корректный ИНН - 10 цифр',
        ],
        kpp: this.actor === 'receiver' ? [() => true] : [(v) => /^(\d{9})$/.test(v) || 'Введите корректный КПП - 9 цифр'],
        passportSeries: [
          (v) => /^(\d{4})$/.test(v) || 'Серия должна состоять из 4 цифр',
        ],
        passportNumber: [
          (v) => /^(\d{6})$/.test(v) || 'Номер должен состоять из 6 цифр',
        ],
        bik: this.actor === 'receiver' ? [() => true] : [(v) => /^(\d{9})$/.test(v) || 'Введите корректный БИК - 9 цифр'],
        bankAccount: this.actor === 'receiver' ? [() => true] : [
          (v) =>
            /^((408|407|405|406){1}\d{17})$/.test(v) ||
            'Введите корректный банковский счет - 20 цифр',
        ],
      },
      select: 'Физическое лицо',
      firstStepPassed: false,
      items: [
        'Юридическое лицо',
        'Физическое лицо',
        'Индивидуальный предприниматель',
      ],
      isOrgExist: false,
      isValid: true,
      isLoading: false,
      clearFields(obj) {
        for (const key of Object.keys(obj)) {
          if (key === 'type' || key === 'variant') {
            continue;
          }
          if (typeof obj[key] === 'object' && obj[key]) {
            this.clearFields(obj[key]);
          } else {
            obj[key] = '';
          }
        }
      },
      individual: {
        type: 'ФизическоеЛицо',
        variant: 'ФЛ',
        name: '',
        surname: '',
        patronymic: '',
        inn: '',
        passport: {
          series: '',
          number: '',
          whoIssued: '',
          issueDate: new Date()
            .toLocaleDateString()
            .split('.')
            .reverse()
            .join('-'),
        },
        contact: {
          contactcellphone: '',
          contactemail: '',
          contactadress: '',
        },
      },
      entity: {
        type: 'ЮридическоеЛицо',
        variant: 'ЮР',
        inn: '',
        kpp: '',
        name: '',
        fullName: '',
        bankAccount: '',
        bik: '',
      },
      entrepreneur: {
        type: 'ЮридическоеЛицо',
        variant: 'ИП',
        fullName: '',
        name: '',
        surname: '',
        patronymic: '',
        inn: '',
        bankAccount: '',
        bik: '',
      },
      validateRules: [(v) => !!v || 'Поле необходимо заполнить'],
    };
  },
  computed: {
    currentOrgType() {
      return this.select === 'Физическое лицо'
        ? this.individual
        : this.select === 'Юридическое лицо'
        ? this.entity
        : this.entrepreneur;
    },
    showModal: {
      get() {
        return this.isShowing;
      },
      set(value) {
        this.$emit('update:isShowing', value);
      },
    },
    whoDoWeCreate() {
      let value = '';
      let action = '';
      if (this.editData) {
        action = 'Редактирование';
      } else {
        action = 'Создание';
      }
      switch (this.actor) {
        case 'payer':
          value = action + ' плательщика';
          break;

        case 'sender':
          value = action + ' отправителя';
          break;

        case 'receiver':
          value = action + ' получателя';
          break;
      }
      return value;
    },
  },
  watch: {
    editData: async function () {
      if (!this.editData) {
        this.select = 'Физическое лицо';
        this.firstStepPassed = false;
      } else {
        try {
          await this.$store.dispatch('getClient', this.editData.id);
          this.firstStepPassed = true;
          if (this.$store.getters.currentClient.inn.length === 10) {
            this.select = 'Юридическое лицо';
          } else if (
            this.$store.getters.currentClient.inn.length === 12 &&
            this.$store.getters.currentClient.type === 'ЮридическоеЛицо'
          ) {
            this.select = 'Индивидуальный предприниматель';
          } else {
            this.select = 'Физическое лицо';
          }
          for (const [key, value] of Object.entries(
            this.$store.getters.currentClient
          )) {
            this.currentOrgType[key] = value;
          }
        } catch (error) {
          this.showAlert(error);
        }
      }
    },
  },
  methods: {
    checkOnNull(object) {
      if (!object) {
        return;
      }
      const array = Object.values(object);
      const modifiedArray = array.map((el) => {
        if (el && typeof el === 'object') {
          return this.checkOnNull(el);
        }
        return el;
      });

      return modifiedArray.flat();
    },
    async submit() {
      this.isValid = this.$refs.form.validate();
      if (!this.isValid) {
        return;
      }
      try {
        this.isLoading = true;
        if (this.editData) {
          await this.$store.dispatch('patchClient', {
            id: this.editData.id,
            data: this.currentOrgType,
          });
        } else {
          await this.$store.dispatch('createClient', this.currentOrgType);
        }
        this.isLoading = false;
        this.close();
      } catch (error) {
        this.showAlert(error);
      }
    },
    close() {
      (this.select = 'Физическое лицо'),
        (this.firstStepPassed = false),
        this.$refs.form.reset();
      this.clearFields(this.currentOrgType);
      this.$emit('close');
      this.$emit('clearData');
    },
    isCreatingOrgExists() {
      const foundedOrg = this.$store.getters.currentClients.find(
        (org) => org.inn === this.entity.inn
      );
      if (foundedOrg && foundedOrg.kpp === this.entity.kpp) {
        this.isOrgExist = true;
        return true;
      }
      return false;
    },
    nextStep() {
      this.$refs.form.validate();
      if (!this.isValid) {
        return;
      }
      switch (this.select) {
        case 'Юридическое лицо':
          if (this.actor !== 'receiver' && (!this.entity.inn || !this.entity.kpp)) {
            return;
          }
          break;
        case 'Индивидуальный предприниматель':
          if (this.actor !== 'receiver' && (!this.entrepreneur.inn)) {
            return;
          }
      }
      if (this.isCreatingOrgExists()) {
        for (const key of Object.keys(this.entity)) {
          if (key !== 'inn' || key !== 'type' || key !== 'variant') {
            this.entity[key] = '';
          }
        }
        return;
      }
      this.firstStepPassed = true;
    },
    async getData(inn, orgType) {
      if (!inn) {
        return;
      }
      try {
        const response = await daData.getData(inn, daData.baseURLData);
        const suggestions = response.data.suggestions[0]?.data;
        if (suggestions && orgType === 'Юридическое лицо') {
          this.entity.name = suggestions.name.short_with_opf;
          this.entity.fullName = suggestions.name.full_with_opf;
          this.entity.inn = suggestions.inn;
          this.entity.kpp = suggestions.kpp;
        } else if (
          suggestions &&
          orgType === 'Индивидуальный предприниматель'
        ) {
          this.entrepreneur.fullName = suggestions.name.full_with_opf;
          this.entrepreneur.name = suggestions.fio.name;
          this.entrepreneur.surname = suggestions.fio.surname;
          this.entrepreneur.patronymic = suggestions.fio.patronymic;
          this.entrepreneur.inn = suggestions.inn;
        } else {
          this.showAlert({ message: 'Введены некорректные данные' });
          this.isValid = false;
        }
      } catch (error) {
        this.showAlert(error);
        this.isValid = false;
      }
    },
  },
};
</script>

<style lang="scss">
.reference-creation {
  &__form {
    width: 100%;
    background-color: #fff;
  }
  &__title {
    padding: 0;
    margin-bottom: 15px;
  }
  &__progress {
    margin: 50px 0;
  }
  &__card {
    width: 100%;
    padding: 15px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__btn {
    &:first-child {
      margin-right: 30px;
    }
    &-wrapper {
      margin-left: auto;
      width: fit-content;
    }
  }
  &__title {
    font-size: 18px;
    font-weight: 500;
  }
}
</style>

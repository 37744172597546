<template>
  <section>
    <v-card class="order-form px-4 py-4">
      <button class="close-button" type="button" @click="cancel">Закрыть</button>

      <v-form ref="mainForm" v-model="isValid">
        <v-card-title class="d-flex justify-space-between">
          <h3>
            {{
              order.number ? 'Накладная №' + order.number : 'Новая накладная'
            }}
          </h3>
          <div v-if="order.id">Дата создания {{ order.date }}</div>
        </v-card-title>
        <v-layout class="mb-2">
          <v-text-field
            v-model="order.counterparty.name"
            label="Инициатор"
            outlined
            dense
            disabled
          />
        </v-layout>
        <v-layout row class="px-3 mb-1">
          <v-text-field
            v-model="order.sender.name"
            label="Отправитель"
            outlined
            dense
            required
            :rules="noEmptyFields"
            readonly
          />
          <v-btn
            class="ml-1 mr-3"
            @click="selectFromReference('sender', false, order)"
          >
            ...
          </v-btn>
          <v-text-field
            v-model="order.sender_contact.name"
            label="Контактное лицо отправителя"
            outlined
            dense
            required
            :rules="noEmptyFields"
            readonly
          />
          <v-btn
            class="ml-1"
            @click="getCurrentContact('sender', 'sender_contact', order)"
          >
            ...
          </v-btn>
        </v-layout>
        <v-layout class="order-form__wrapper mb-2">
          <v-text-field
            ref="senderInput"
            v-model="order.sender_address.value"
            label="Адрес отправителя"
            outlined
            dense
            required
            :rules="noEmptyFields"
            @input="
              getLocation(order.sender_address.value, 'sender');
              showSenderAddresses = true;
            "
          />
          <v-list
            v-if="showSenderAddresses"
            ref="sender-addreses"
            class="order-form__list order-form__list--sender address-list"
            outlined
            tile
          >
            <v-subheader class="address-list__header"> Адреса </v-subheader>
            <p
              v-if="order.sender_address.value.trim().length < 5"
              class="adress-list__message"
            >
              Введите не менее 5 символов
            </p>
            <v-progress-circular
              v-if="
                !senderAddresses.length &&
                order.sender_address.value.trim().length >= 5
              "
              indeterminate
              color="primary"
              class="address-list__progress"
              size="40"
              width="5"
            />
            <v-list-item-group
              v-if="senderAddresses.length"
              v-model="order.sender_address.value"
              color="primary"
              class="address-list__list"
            >
              <v-list-item
                v-for="(address, i) in senderAddresses"
                :key="i"
                :value="address.value"
                class="address-list__item"
                @click="
                  showSenderAddresses = false;
                  order.sender_address.oktmo = address.data.oktmo;
                  order.sender_address.city = address.data.city;
                  order.sender_address.country = address.data.country;
                "
              >
                <v-list-item-content>
                  <v-list-item-title v-text="address.value" />
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-layout>

        <template v-if="order.id">
          <v-layout class="mb-1">
            <v-text-field
              v-model="order.receiver.name"
              label="Получатель"
              outlined
              dense
              required
              :rules="noEmptyFields"
              readonly
            />
            <v-btn
              class="ml-1 mr-3"
              @click="selectFromReference('receiver', false, order)"
            >
              ...
            </v-btn>
            <v-text-field
              v-model="order.receiver_contact.name"
              label="Контактное лицо получателя"
              outlined
              dense
              required
              :rules="noEmptyFields"
              readonly
            />
            <v-btn
              class="ml-1"
              @click="getCurrentContact('receiver', 'receiver_contact', order)"
            >
              ...
            </v-btn>
          </v-layout>
          <v-layout class="order-form__wrapper mb-2">
            <v-text-field
              v-model="order.receiver_address.value"
              label="Адрес получателя"
              outlined
              dense
              required
              :rules="noEmptyFields"
              @input="
                getLocation(order.receiver_address.value, 'receiver');
                showReceiverAddresses = true;
              "
            />
            <v-list
              v-if="showReceiverAddresses"
              ref="receiver-addreses"
              outlined
              tile
              class="order-form__list order-form__list--receiver address-list"
            >
              <v-subheader class="address-list__header"> Адреса </v-subheader>
              <p
                v-if="order.receiver_address.value.trim().length < 5"
                class="adress-list__message"
              >
                Введите не менее 5 символов
              </p>
              <v-progress-circular
                v-if="
                  !receiverAddresses.length &&
                  order.receiver_address.value.trim().length >= 5
                "
                indeterminate
                color="primary"
                class="address-list__progress"
                size="50"
                width="5"
              />
              <v-list-item-group
                v-if="receiverAddresses.length"
                v-model="order.receiver_address.value"
                class="address-list__list"
                color="primary"
              >
                <v-list-item
                  v-for="(address, i) in receiverAddresses"
                  :key="i"
                  class="address-list__item"
                  width="100%"
                  :value="address.value"
                  @click="
                    showReceiverAddresses = false;
                    order.receiver_address.oktmo = address.data.oktmo;
                    order.receiver_address.city = address.data.city;
                    order.receiver_address.country = address.data.country;
                    receiverAddresses = [];
                  "
                >
                  <v-list-item-content>
                    <v-list-item-title width="100%" v-text="address.value" />
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-layout>
          <v-row v-if="order.receiver.id && order.sender.id" row align="center">
            <v-col cols="1">
              <v-subheader>Плательщик</v-subheader>
            </v-col>
            <v-col cols="3">
              <v-select
                v-model="currentPayer"
                :items="potencialPayers"
                label="Выберите плательщика"
                single-line
              />
            </v-col>
          </v-row>
          <v-layout v-if="currentPayer === 'Другое лицо'">
            <v-text-field
              v-model="order.payer.name"
              label="Плательщик"
              outlined
              dense
              required
              :rules="noEmptyFields"
              readonly
            />
            <v-btn
              class="ml-1 mr-3"
              @click="selectFromReference('payer', true, order)"
            >
              ...
            </v-btn>
          </v-layout>
          <v-layout class="px-3" row>
            <v-row>
              <v-col cols="3">
                <v-radio-group
                  v-model="order.paymentType"
                  :disabled="isPaymentTypeDisabled"
                  column
                >
                  <template v-slot:label>
                    <div class="mb-2">Тип оплаты</div>
                  </template>
                  <v-layout align-center row>
                    <v-radio
                      v-for="paymentType in paymentTypes"
                      :key="paymentType.id"
                      class="mb-0 mr-2"
                      :value="paymentType"
                      :label="paymentType.value"
                    />
                  </v-layout>
                </v-radio-group>
              </v-col>
              <v-col cols="3">
                <v-radio-group v-model="order.urgency" column>
                  <template v-slot:label>
                    <div class="mb-2">Срочность</div>
                  </template>
                  <v-layout align-center row>
                    <v-radio
                      v-for="(urgency, innerIndex) in urgencyVariants"
                      :key="innerIndex"
                      class="mb-0"
                      :value="urgency"
                      :label="urgency"
                    />
                  </v-layout>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-layout>
          <v-layout row align-center class="px-3 py-2 my-5">
            Содержание груза ({{ formattedItems(order).length }} позиций):
            <v-spacer />
            <v-btn
              :disabled="!order.itemscount"
              class="mr-3"
              @click="getPrice(order)"
            >
              Рассчитать стоимость
            </v-btn>
            <v-btn color="primary" @click="addRow(order)">
              Добавить строку с описанием груза
            </v-btn>
          </v-layout>
          <v-layout
            v-for="(cargo, innerIndex) in formattedItems(order)"
            :key="innerIndex"
            class="d-flex flex-column px-3 py-2"
          >
            <v-row class="ma-0">
              <v-select
                v-model="cargo.cargoType"
                outlined
                dense
                label="Тип груза"
                :item-text="(item) => item.value"
                :item-value="(item) => item"
                :items="doctypes"
                style="width: 80px"
                hide-details
                class="mr-1"
              />
              <v-text-field
                v-model="cargo.width"
                label="Ширина,см."
                outlined
                dense
                hide-details
                style="width: 40px"
                class="mr-1"
                type="number"
              />
              <v-text-field
                v-model="cargo.height"
                label="Высота,см."
                outlined
                dense
                hide-details
                style="width: 40px"
                class="mr-1"
                type="number"
              />
              <v-text-field
                v-model="cargo.length"
                label="Длина,см."
                outlined
                dense
                hide-details
                style="width: 40px"
                class="mr-1"
              />
              <v-text-field
                v-model="cargo.weight"
                label="Вес,кг."
                outlined
                dense
                hide-details
                style="width: 40px"
                class="mr-1"
                type="number"
              />
              <v-text-field
                v-model="cargo.description"
                label="Описание груза"
                outlined
                dense
                :rules="[
                  (v) =>
                    v.length <= 165 ||
                    'Длина описания не может превышать 165 символов',
                ]"
                class="mr-1"
              />
              <v-icon class="mb-7" color="red" @click="deleteRow(order, innerIndex)">
                mdi-delete
              </v-icon>
              <v-icon class="mb-7 ml-2" color="blue" @click="copyRow(order, innerIndex)">
                mdi-content-copy
              </v-icon>
            </v-row>
            <v-row class="d-flex flex-column ma-0">
              <div class="">Дополнительные услуги:</div>
              <v-layout class="d-flex">
                <v-checkbox
                  class="ma-0 mr-2"
                  v-for="(value, name) in cargo.services"
                  :key="value.caption"
                  v-model="cargo.services[name].value"
                  :label="value.caption"
                ></v-checkbox>
              </v-layout>
            </v-row>
          </v-layout>
          <div class="mt-3">
            <strong v-if="order.summa"
              >Стоимость составит: {{ order.summa }} рублей</strong
            >
          </div>
        </template>
      </v-form>

      <ul v-if="!order.id" class="receiver__list">
        <li
          class="receiver__item"
          v-for="(receiver, index) in receiversData"
          :key="index"
        >
          <v-form
            class="pa-3 grey lighten-4"
            ref="forms"
            v-model="receiver.isValid"
          >
            <h4 class="mb-2">Получатель {{ index + 1 }}</h4>
            <v-layout class="mb-1">
              <v-text-field
                v-model="receiver.receiver.name"
                label="Получатель"
                outlined
                dense
                required
                :rules="noEmptyFields"
                readonly
              />
              <v-btn
                class="ml-1 mr-3"
                @click="selectFromReference('receiver', false, receiver)"
              >
                ...
              </v-btn>
              <v-text-field
                v-model="receiver.receiver_contact.name"
                label="Контактное лицо получателя"
                outlined
                dense
                required
                :rules="noEmptyFields"
                readonly
              />
              <v-btn
                class="ml-1"
                @click="
                  getCurrentContact('receiver', 'receiver_contact', receiver)
                "
              >
                ...
              </v-btn>
            </v-layout>
            <v-layout class="order-form__wrapper mb-2">
              <v-text-field
                v-model="receiver.receiver_address.value"
                label="Адрес получателя"
                outlined
                dense
                required
                :rules="noEmptyFields"
                @input="
                  receiver.showAddresses = true;
                  getLocation(receiver.receiver_address.value, 'receiver');
                "
              />
              <v-list
                v-if="receiver.showAddresses"
                ref="receiver-addreses"
                outlined
                tile
                class="order-form__list order-form__list--receiver address-list"
              >
                <v-subheader class="address-list__header"> Адреса </v-subheader>
                <p
                  v-if="receiver.receiver_address.value.trim().length < 5"
                  class="adress-list__message"
                >
                  Введите не менее 5 символов
                </p>
                <v-progress-circular
                  v-if="
                    !receiverAddresses.length &&
                    receiver.receiver_address.value.trim().length >= 5
                  "
                  indeterminate
                  color="primary"
                  class="address-list__progress"
                  size="50"
                  width="5"
                />
                <v-list-item-group
                  v-if="receiverAddresses.length"
                  v-model="receiver.receiver_address.value"
                  class="address-list__list"
                  color="primary"
                >
                  <v-list-item
                    v-for="(address, i) in receiverAddresses"
                    :key="i"
                    class="address-list__item"
                    width="100%"
                    :value="address.value"
                    @click="
                      receiver.showAddresses = false;
                      receiver.receiver_address.oktmo = address.data.oktmo;
                      receiver.receiver_address.city = address.data.city;
                      receiver.receiver_address.country = address.data.country;
                      receiverAddresses = [];
                    "
                  >
                    <v-list-item-content>
                      <v-list-item-title width="100%" v-text="address.value" />
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-layout>
            <v-row
              v-if="receiver.receiver.id && order.sender.id"
              row
              align="center"
            >
              <v-col cols="1">
                <v-subheader>Плательщик</v-subheader>
              </v-col>
              <v-col cols="3">
                <v-select
                  v-model="receiver.currentPayer"
                  :items="potencialPayers"
                  label="Выберите плательщика"
                  single-line
                  :rules="[(v) => !!v || 'Выберите плательщика']"
                />
              </v-col>
            </v-row>
            <v-layout v-if="receiver.currentPayer === 'Другое лицо'">
              <v-text-field
                v-model="receiver.payer.name"
                label="Плательщик"
                outlined
                dense
                required
                :rules="noEmptyFields"
                readonly
              />
              <v-btn
                class="ml-1 mr-3"
                @click="selectFromReference('payer', true, receiver)"
              >
                ...
              </v-btn>
            </v-layout>
            <v-layout class="px-3" row>
              <v-row>
                <v-col cols="3">
                  <v-radio-group
                    v-model="receiver.paymentType"
                    :disabled="isPaymentTypeDisabled"
                    column
                  >
                    <template v-slot:label>
                      <div class="mb-2">Тип оплаты</div>
                    </template>
                    <v-layout align-center row>
                      <v-radio
                        v-for="paymentType in paymentTypes"
                        :key="paymentType.id"
                        class="mb-0 mr-2"
                        :value="paymentType"
                        :label="paymentType.value"
                      />
                    </v-layout>
                  </v-radio-group>
                </v-col>
                <v-col cols="3">
                  <v-radio-group v-model="receiver.urgency" column>
                    <template v-slot:label>
                      <div class="mb-2">Срочность</div>
                    </template>
                    <v-layout align-center row>
                      <v-radio
                        v-for="(urgency, innerIndex) in urgencyVariants"
                        :key="innerIndex"
                        class="mb-0"
                        :value="urgency"
                        :label="urgency"
                      />
                    </v-layout>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-layout>
            <v-layout row align-center class="px-3 py-2 my-5">
              Содержание груза ({{ formattedItems(receiver).length }} позиций):
              <v-spacer />
              <v-btn
                :disabled="!receiver.itemscount"
                class="mr-3"
                @click="getPrice(receiver, index)"
                >Рассчитать стоимость
              </v-btn>
              <v-btn class="mr-3" color="primary" @click="addRow(receiver)">
                Добавить строку с описанием груза
              </v-btn>
              <v-btn
                v-if="!order.id && receiversData.length > 1"
                color="error"
                @click="removeReceiver(receiver)"
                >Удалить получателя</v-btn
              >
            </v-layout>
            <v-layout
              v-for="(cargo, innerIndex) in formattedItems(receiver)"
              :key="innerIndex"
              class="d-flex flex-column px-3 py-2 justify-center"
            >
              <v-row class="ma-0">
                <v-select
                  v-model="cargo.cargoType"
                  outlined
                  dense
                  label="Тип груза"
                  :item-text="(item) => item.value"
                  :item-value="(item) => item"
                  :items="doctypes"
                  style="width: 80px"
                  hide-details
                  class="mr-1"
                />
                <v-text-field
                  v-model="cargo.width"
                  label="Ширина,см."
                  outlined
                  dense
                  hide-details
                  style="width: 40px"
                  class="mr-1"
                />
                <v-text-field
                  v-model="cargo.height"
                  label="Высота,см."
                  outlined
                  dense
                  hide-details
                  style="width: 40px"
                  class="mr-1"
                />
                <v-text-field
                  v-model="cargo.length"
                  label="Длина,см."
                  outlined
                  dense
                  hide-details
                  style="width: 40px"
                  class="mr-1"
                />
                <v-text-field
                  v-model="cargo.weight"
                  label="Вес,кг."
                  outlined
                  dense
                  hide-details
                  style="width: 40px"
                  class="mr-1"
                />
                <v-text-field
                  v-model="cargo.description"
                  label="Описание груза"
                  outlined
                  dense
                  :rules="[
                    (v) =>
                      v.length <= 165 ||
                      'Длина описания не может превышать 165 символов',
                  ]"
                  class="mr-1"
                />
                <v-icon class="mb-7" color="red" @click="deleteRow(receiver, innerIndex)">
                  mdi-delete
                </v-icon>
                <v-icon class="mb-7 ml-2" color="blue" @click="copyRow(receiver, innerIndex)">
                  mdi-content-copy
                </v-icon>
              </v-row>
              <v-row class="d-flex flex-column ma-0">
                <div class="">Дополнительные услуги:</div>
                <v-layout class="d-flex">
                  <v-checkbox
                    class="ma-0 mr-2"
                    v-for="(value, name) in cargo.services"
                    :key="value.caption"
                    v-model="cargo.services[name].value"
                    :label="value.caption"
                  ></v-checkbox>
                </v-layout>
              </v-row>
            </v-layout>
            <div class="mt-3">
              <strong class="mr-3" v-if="receiver.summa"
                >Стоимость составит: {{ receiver.summa }} рублей</strong
              >
            </div>
          </v-form>
        </li>
      </ul>

      <v-layout class="my-3">
        <v-menu
          v-if="order.id && $store.state.user.role === 'Администратор'"
          top
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on">
              Журнал изменения накладной
            </v-btn>
          </template>
          <v-list v-if="differences.length" class="order-history__list">
            <v-list-item
              v-for="(item, index) in differences"
              :key="index"
              class="
                d-flex
                flex-column
                align-start
                order-history__item
                order-version
              "
            >
              <v-list-item-title class="mb-3 order-history__title">
                <template v-if="index !== differences.length - 1">
                  Измененные данные от {{ item['Дата'] }}
                </template>
                <template v-else>
                  Перворначальная версия от {{ item['Дата'] }}
                </template>
              </v-list-item-title>
              <v-list-item
                v-for="(value, name, index) in compPrevVersions(index)"
                :key="index"
                class="order-history__item"
              >
                <template
                  v-if="
                    !Array.isArray(value) &&
                    typeof Object.values(value)[0] === 'object'
                  "
                  class="order-history__item"
                >
                  {{ name }}:
                  <div
                    class="d-flex ml-2 order-history__prop"
                    v-for="(innerValue, innerName, index) in value"
                    :key="index"
                  >
                    {{ innerName }}:
                    <div class="mx-2 d-flex was">
                      <div class="state mr-2">Было:</div>
                      {{ innerValue.was }} ;
                    </div>
                    <div class="d-flex become">
                      <div class="state mr-2">Стало:</div>
                      {{ innerValue.become }} ;
                    </div>
                  </div>
                </template>
                <template
                  v-else-if="!Array.isArray(value)"
                  class="order-history__item"
                >
                  {{ name }}:
                  <div class="mx-2 d-flex was">
                    <div class="state mr-2">Было:</div>
                    {{ value.was }} ;
                  </div>
                  <div class="d-flex become">
                    <div class="state mr-2">Стало:</div>
                    {{ value.become }} ;
                  </div>
                </template>
                <v-list
                  v-else
                  class="align-start order-history__list inner-list"
                >
                  <v-list-item-title class="mb-2 inner-list__title">
                    {{ name }}:
                  </v-list-item-title>
                  <v-list-item
                    v-for="(innerValue, index) in value"
                    :key="index"
                    class="
                      flex-column
                      align-start
                      order-history__item
                      inner-item
                      pa-0
                    "
                  >
                    <div v-if="innerValue" class="number-of-cargo">
                      Груз {{ index + 1 }}:
                    </div>
                    <div
                      class="d-flex"
                      v-for="(value, name, index) in innerValue"
                      :key="index"
                    >
                      {{ name }}:
                      <div class="mx-2 d-flex was">
                        <div class="state mr-2">Было:</div>
                        {{ value.was }} ;
                      </div>
                      <div class="d-flex become">
                        <div class="state mr-2">Стало:</div>
                        {{ value.become }} ;
                      </div>
                    </div>
                  </v-list-item>
                </v-list>
              </v-list-item>
            </v-list-item>
          </v-list>
          <div v-else class="no-changes">
            Изменений в накладной не было
            </div>
        </v-menu>
      </v-layout>

      <v-layout class="d-flex justify-space-between align-center">
        <div class="d-flex align-center">
          <v-btn v-if="!order.id" @click="addReceiver" class="mr-3"
            >Добавить получателя</v-btn
          >
        </div>
        <v-card-actions class="flex justify-end">
          <v-btn class="mr-2" color="primary" :disabled="$props.loading" @click="save"> Сохранить </v-btn>
          <v-btn v-if="order.id" @click="downloadOrder(order.id, order.number)">
            Скачать накладную
          </v-btn>
          <v-btn v-if="order.id" @click="downloadLabel(order.id, order.number)">
            Скачать этикетку
          </v-btn>
          <v-btn class="mr-2" @click="cancel"> Отмена </v-btn>
          <v-btn
            v-if="order.id"
            color="error"
            @click="
              processingData({
                action: 'delete',
                data: order.id,
                isOrder: true,
              })
            "
          >
            Удалить
          </v-btn>
        </v-card-actions>
      </v-layout>
    </v-card>
    <reference-dialog
      ref="refDialog"
      @create="create"
      @contacts="contactsModal = true"
      @action="processingData"
    />
    <ReferenceCreation
      :actor="actor"
      :edit-data="editData"
      :is-showing.sync="isCreationModalShow"
      @close="isCreationModalShow = false"
      @clearData="editData = ''"
    />

    <ContactsCreation
      :contacts-data="contactsData"
      :client-id="$store.getters.currentClient.id"
      :is-showing.sync="contactsModal"
      @clearData="
        (data) => {
          contactsData = data;
        }
      "
    />
    <v-dialog v-model="noOrgChosen" class="no-org-chosen" width="25%">
      <v-card class="no-org-chosen__card">
        <v-card-title class="no-org-chosen__title">
          Пожалуйста, {{ typeOfContact }}
        </v-card-title>
        <v-btn
          color="primary"
          class="no-org-chosen__btn"
          @click="noOrgChosen = false"
        >
          ОК
        </v-btn>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="deleteModal"
      class="processing"
      width="30%"
      is-delete-modal
    >
      <v-card class="processing__card">
        <template v-if="!isProcessing">
          <v-card-title> Вы уверены, что хотите удалить данные? </v-card-title>
          <v-card-actions>
            <v-btn color="error" @click="deleteClientData(dataToDelete)">
              Удалить
            </v-btn>
            <v-btn @click="deleteModal = false"> Отмена </v-btn>
          </v-card-actions>
        </template>
        <v-progress-circular
          v-if="isProcessing"
          class="processing__process"
          indeterminate
          size="50"
          width="6"
          color="primary"
        />
      </v-card>
    </v-dialog>
  </section>
</template>
<script>
import API from '@/api/api';
import ReferenceDialog from '@/components/ReferenceDialog';
import ReferenceCreation from '@/components/ReferenceCreation.vue';
import ContactsCreation from '@/components/ContactsCreation.vue';
import daData from '@/api/daData';
import _ from 'lodash';

export default {
  name: 'OrderForm',
  components: {
    ReferenceDialog,
    ReferenceCreation,
    ContactsCreation,
  },
  props: {
    loading: Boolean,
  },
  data() {
    return {
      order: {
        id: '',
        number: '',
        date: '',
        counterparty: {
          id: '',
          name: '',
        },
        contact: {
          id: '',
          name: '',
        },
        sender: {
          id: '',
          name: '',
          type: '',
        },
        sender_contact: {
          id: '',
          name: '',
        },
        sender_address: {
          value: '',
          oktmo: '',
          city: '',
          country: '',
        },
        receiver: {
          id: '',
          name: '',
          type: '',
        },
        receiver_contact: {
          id: '',
          name: '',
        },
        receiver_address: {
          value: '',
          oktmo: '',
          city: '',
          country: '',
        },
        payer: {
          id: '',
          name: '',
          type: '',
        },
        status: {
          id: '',
          value: '',
        },
        summa: 0,
        itemscount: 0,
        items: [],
        urgency: 'Обычная',
        services: {
          box: {
            caption: 'Коробка',
            value: false,
          },
          bubbleWrap: {
            caption: 'Пупырчатая плёнка',
            value: false,
          },
          sterchFilm: {
            caption: 'Стрейч',
            value: false,
          },
          lathing: {
            caption: 'Обрешетка',
            value: false,
          },
        },
        paymentType: {
          id: 'Безналичный',
          value: 'Безналичный',
        },
      },
      paymentTypes: [
        {
          id: 'Безналичный',
          value: 'Безналичный',
        },
        {
          id: 'Наличный',
          value: 'Наличный',
        },
      ],
      urgencyVariants: ['Обычная', 'Экспресс'],
      oldVersions: [],
      differences: [],
      doctypes: [
        { id: 'Документы', value: 'Документы' },
        { id: 'Прочее', value: 'Прочее' },
      ],
      isCreationModalShow: false,
      potencialPayers: ['Отправитель', 'Получатель', 'Другое лицо'],
      currentPayer: '',
      actor: '',
      contactsModal: false,
      senderAddresses: [],
      receiverAddresses: [],
      showSenderAddresses: false,
      showReceiverAddresses: false,
      timeout: '',
      editData: '',
      contactsData: '',
      currentContact: '',
      noOrgChosen: false,
      isValid: true,
      isProcessing: false,
      deleteModal: false,
      dataToDelete: null,
      noEmptyFields: [(v) => !!v || 'Поле обязательно для заполнения'],
      receiversData: [
        {
          receiver: {
            id: '',
            name: '',
            type: '',
          },
          receiver_contact: {
            id: '',
            name: '',
          },
          receiver_address: {
            value: '',
            oktmo: '',
          },
          payer: {
            id: '',
            name: '',
            type: '',
          },
          status: {
            id: '',
            value: '',
          },
          summa: 0,
          itemscount: 0,
          items: [],
          urgency: 'Обычная',
          paymentType: {
            id: 'Безналичный',
            value: 'Безналичный',
          },
          services: {
            box: {
              caption: 'Коробка',
              value: false,
            },
            bubbleWrap: {
              caption: 'Воздушно-пузырьковая пленка',
              value: false,
            },
            sterchFilm: {
              caption: 'Стретч',
              value: false,
            },
            lathing: {
              caption: 'Обрешетка',
              value: false,
            },
          },
          showAddresses: false,
          currentPayer: '',
          isValid: true,
        },
      ],
    };
  },
  computed: {
    typeOfContact() {
      return this.currentContact === 'sender_contact'
        ? 'укажите отправителя'
        : 'укажите получателя';
    },
    isPaymentTypeDisabled() {
      return this.order.payer.type === 'ФизическоеЛицо' ? true : false;
    },
    compPrevVersions() {
      return function (index) {
        const localObj = {};
        for (const key of Object.keys(this.differences[index])) {
          if (key === 'Дата') {
            continue;
          } else {
            localObj[key] = this.differences[index][key];
          }
        }
        return localObj;
      };
    },
    orders() {
      const localOrder = JSON.parse(JSON.stringify(this.order));
      return this.receiversData.map((receiver) => {
        const mappedObj = {};
        for (const [key, value] of Object.entries(receiver)) {
          if (key === 'showAddresses' || key === 'payer' || key === 'isValid') {
            continue;
          } else {
            if (key === 'currentPayer') {
              switch (value) {
                case 'Отправитель':
                  mappedObj['payer'] = localOrder['sender'];
                  break;

                case 'Получатель':
                  mappedObj['payer'] = receiver['receiver'];
                  break;
              }
              continue;
            }
            mappedObj[key] = value;
          }
        }
        return {
          ...localOrder,
          ...mappedObj,
        };
      });
    },
  },
  watch: {
    '$store.state.orders.currentHistory': {
      handler(value) {
        this.oldVersions = value;
      },
      immediate: true,
    },
    '$store.state.orders.order': {
      handler(value) {
        this.order = value;
        if (this.$refs.mainForm) {
          this.$refs.mainForm.resetValidation();
        }
        if (value?.counterparty?.id) {
          this.order.counterparty.id = value.counterparty.id;
          this.order.counterparty.name = value.counterparty.name;
        } else {
          this.order.counterparty.id = this.$store.state.user.id;
          this.order.counterparty.name = this.$store.state.user.orgname;
        }
      },
      immediate: true,
    },
    oldVersions: {
      handler() {
        this.differences = [];
        this.checkForDifferences();
      },
      immediate: true,
    },
    'order.sender_contact': async function (newValue) {
      const contactIndex = this.$store.state.clients.currentClient.contacts.findIndex((contact) => contact.id === newValue.id);
      const contactAddressString = this.$store.state.clients.currentClient.contacts[contactIndex].contactadress;

      const response = await daData.getData(contactAddressString, daData.baseURLAddress);

      if (response.data?.suggestions?.length) {
        const address = response.data.suggestions[0];

        this.order.sender_address = {
          city: address.data.city,
          country: address.data.country,
          oktmo: address.data.oktmo,
          value: address.value,
        };
      }
    },
    'order.sender.name': function (newValue, oldValue) {
      if (!oldValue) {
        return;
      }
      if (newValue !== oldValue) {
        if (this.order.sender.id !== this.order.payer.id) {
          this.order.payer.type = this.order.sender.type;
        }
        this.order.sender_contact = {
          id: '',
          name: '',
        };
      }
    },
    'order.receiver.name': function (newValue, oldValue) {
      if (!oldValue) {
        return;
      }
      if (newValue !== oldValue) {
        if (this.order.receiver.id !== this.order.payer.id) {
          this.order.payer.type = this.order.receiver.type;
        }

        this.potencialPayers = ['Отправитель', 'Получатель', 'Другое лицо'];

        if (!this.order.receiver.inn) {
          this.currentPayer = '';
          this.potencialPayers = ['Отправитель', 'Другое лицо'];
        }

        this.order.receiver_contact = {
          id: '',
          name: '',
        };
      }
    },
    isPaymentTypeDisabled: function (value) {
      if (value) {
        this.paymentType = 'Наличный';
      }
    },
    'order.id': {
      async handler(value) {
        switch (this.order.payer.id) {
          case '':
            this.currentPayer = '';
            break;
          case this.order.sender.id:
            this.currentPayer = 'Отправитель';
            break;

          case this.order.receiver.id:
            this.currentPayer = 'Получатель';
            break;
        }
        if (!value) {
          return;
        }
        const { data: receiverSuggestions } = await this.getLocation(
          this.order.receiver_address.value,
          'receiver',
          false
        );
        const { data: senderSuggestions } = await this.getLocation(
          this.order.sender_address.value,
          'sender',
          false
        );

        const { data: rereceiverData } = receiverSuggestions.suggestions.find(
          (suggestion) => {
            if (suggestion.value === this.order.receiver_address.value) {
              return suggestion;
            } else {
              return false;
            }
          }
        );

        const { data: senderData } = senderSuggestions.suggestions.find(
          (suggestion) => {
            if (suggestion.value === this.order.sender_address.value) {
              return suggestion;
            } else {
              return false;
            }
          }
        );

        this.order.receiver_address.oktmo = rereceiverData.oktmo;
        this.order.receiver_address.city = rereceiverData.city;
        this.order.receiver_address.country = rereceiverData.country;

        this.order.sender_address.oktmo = senderData.oktmo;
        this.order.sender_address.city = senderData.city;
        this.order.sender_address.country = senderData.country;
      },
      immediate: true,
    },
    currentPayer(value) {
      switch (value) {
        case 'Отправитель':
          this.order.payer.id = this.order.sender.id;
          this.order.payer.name = this.order.sender.name;
          this.order.payer.type = this.order.sender.type;
          break;

        case 'Получатель':
          this.order.payer.id = this.order.receiver.id;
          this.order.payer.name = this.order.receiver.name;
          this.order.payer.type = this.order.receiver.type;
          break;
      }
    },
  },
  created() {
    if (
      this.$route.params.order
        ? Object.keys(this.$route.params.order).length
        : false
    ) {
      const dataToAdd = this.$route.params.order;
      this.receiversData[0].items = dataToAdd.items;
      this.receiversData[0].itemscount = dataToAdd.itemscount;
      this.receiversData[0].receiver_address.value =
        dataToAdd.receiver_address.value;
      this.receiversData[0].receiver_address.oktmo =
        dataToAdd.receiver_address.oktmo;
      this.receiversData[0].receiver_address.city =
        dataToAdd.receiver_address.city;
      this.receiversData[0].receiver_address.country =
        dataToAdd.receiver_address.country;
      this.receiversData[0].urgency = dataToAdd.urgency;
      this.order.sender_address.oktmo = dataToAdd.sender_address.oktmo;
      this.order.sender_address.city = dataToAdd.sender_address.city;
      this.order.sender_address.country = dataToAdd.sender_address.country;
      this.order.sender_address.value = dataToAdd.sender_address.value;
    }
  },
  methods: {
    async downloadOrder(id, number) {
      this.$emit('action', { action: 'hide' });
      this.$store.commit('setLoader');
      try {
        const result = await API.getBlob(`/waybill/${id}`);
        const url = URL.createObjectURL(result);
        const link = document.createElement('a');
        link.href = url;
        link.download = `order_№${number}.pdf`;
        link.click();
        URL.revokeObjectURL(url);
        link.remove();
      } catch (error) {
        this.showAlert(error);
      } finally {
        this.$store.commit('deleteLoader');
        this.$emit('action', { action: 'show' });
      }
    },
    async downloadLabel(id, number) {
      this.$emit('action', { action: 'hide' });
      this.$store.commit('setLoader');
      try {
        const result = await API.getBlob(`/sticker/${id}`);
        const url = URL.createObjectURL(result);
        const link = document.createElement('a');
        link.href = url;
        link.download = `order_label_№${number}.pdf`;
        link.click();
        URL.revokeObjectURL(url);
        link.remove();
      } catch (error) {
        this.showAlert(error);
      } finally {
        this.$store.commit('deleteLoader');
        this.$emit('action', { action: 'show' });
      }
    },
    formattedItems(receiver) {
      return receiver.items.length
        ? receiver.items.map((item) => {
            if (String(item.width).startsWith('0')) {
              item.width = Number(item.width);
            }
            if (String(item.height).startsWith('0')) {
              item.height = Number(item.height);
            }
            if (String(item.weight).startsWith('0')) {
              item.weight = Number(item.weight);
            }
            if (String(item.length).startsWith('0')) {
              item.length = Number(item.length);
            }
            return item;
          })
        : [];
    },
    addReceiver() {
      this.receiversData.push({
        receiver: {
          id: '',
          name: '',
          type: '',
        },
        receiver_contact: {
          id: '',
          name: '',
        },
        receiver_address: {
          value: '',
          oktmo: '',
        },
        payer: {
          id: '',
          name: '',
          type: '',
        },
        status: {
          id: '',
          value: '',
        },
        summa: 0,
        itemscount: 0,
        items: [],
        urgency: 'Обычная',
        paymentType: {
          id: 'Безналичный',
          value: 'Безналичный',
        },
        showAddresses: false,
        currentPayer: '',
        isValid: true,
      });
    },
    removeReceiver(receiverToDelete) {
      if (this.receiversData.length === 1) {
        return;
      } else {
        this.receiversData = this.receiversData.filter(
          (receiver) => receiver !== receiverToDelete
        );
      }
    },
    async getPrice(receiver, index) {
      this.$refs.mainForm.validate();
      if (this.$refs.forms?.length) {
        if (!this.$refs.forms[index].validate()) {
          this.isValid = false;
        }
      }
      if (!receiver.items.length) {
        this.isValid = false;
        this.noItems = true;
      }
      if (!this.isValid) {
        return;
      }
      const localData = {};

      for (const [key, value] of Object.entries(receiver)) {
        if (
          key !== 'receiver_address' &&
          key !== 'sender_address' &&
          key !== 'urgency' &&
          key !== 'items' &&
          key !== 'itemscount'
        ) {
          continue;
        } else {
          localData[key] = value;
        }
      }
      this.loading = true;
      try {
        const response = await API.post('/invoices/', localData);
        receiver.summa = response.summa;
      } catch (error) {
        this.showAlert(error);
      } finally {
        this.loading = false;
      }
    },
    async deleteOrder(id) {
      this.isProcessing = true;
      try {
        await this.$store.dispatch('deleteOrder', id);
        this.$emit('action', { action: 'cancel' });
      } catch (error) {
        this.showAlert(error);
      } finally {
        this.isProcessing = false;
      }
    },
    async deleteClientData(data) {
      if (data.isOrder) {
        await this.deleteOrder(data.data);
        return;
      }
      if (!data.isContacts) {
        this.isProcessing = true;
        try {
          await this.$store.dispatch('deleteClient', data.item.id);
          this.$refs.refDialog.visible = false;
          this.deleteModal = false;
          if (data.item.id === this.order.sender.id) {
            // по аналогии
            this.order.sender.id = '';
            this.order.sender.name = '';
            this.order.sender.type = '';
          }
          if (data.item.id === this.order.receiver.id) {
            this.order.receiver.id = '';
            this.order.receiver.name = '';
            this.order.receiver.type = '';
          }
          if (data.item.id === this.order.payer.id) {
            this.order.payer.id = '';
            this.order.payer.name = '';
            this.order.payer.type = '';
          }
        } catch (error) {
          this.showAlert(error);
        } finally {
          this.isProcessing = false;
        }
      } else {
        if (data.item.id === this.order.sender_contact.id) {
          // по аналогии
          this.order.sender.id = '';
        }
        if (data.item.id === this.order.receiver_contact.id) {
          this.order.receiver.id = '';
        }
        this.isProcessing = true;
        try {
          await this.$store.dispatch('deleteContact', data.item.id);
          this.$refs.refDialog.visible = false;
          this.deleteModal = false;
        } catch (error) {
          this.showAlert(error);
        } finally {
          this.isProcessing = false;
        }
      }
    },
    async processingData(data) {
      if (data.action === 'delete') {
        this.dataToDelete = data;
        this.deleteModal = true;
        return;
      }
      if (!data.isContacts && data.action === 'edit') {
        this.editData = {
          ...data.item,
          action: data.action,
        };
        this.isCreationModalShow = true;
      } else if (data.isContacts && data.action === 'edit') {
        this.contactsData = data.item;
        this.contactsModal = true;
      }
      this.$refs.refDialog.visible = false;
    },

    async getLocation(address, type, suggestions = true) {
      clearTimeout(this.timeout);
      if (address.length < 5) {
        return;
      }
      if (suggestions) {
        this.timeout = setTimeout(async () => {
          const { data } = await daData.getData(address, daData.baseURLAddress);
          type === 'sender'
            ? (this.senderAddresses = data.suggestions)
            : (this.receiverAddresses = data.suggestions);
        }, 800);
      } else {
        const response = await daData.getData(address, daData.baseURLAddress);
        return response;
      }
    },
    deleteRow(receiver, index) {
      receiver.items.splice(index, 1);
      --receiver.itemscount;
    },
    addRow(receiver) {
      receiver.items.push({
        cargoType: { id: 'Прочее', value: 'Прочее' },
        length: 0,
        width: 0,
        height: 0,
        weight: 0,
        description: '',
        services: {
          box: {
            caption: 'Коробка',
            value: false,
          },
          bubbleWrap: {
            caption: 'Воздушно-пузырьковая пленка',
            value: false,
          },
          stretchFilm: {
            caption: 'Стретч',
            value: false,
          },
          lathing: {
            caption: 'Обрешетка',
            value: false,
          },
        },
      });
      ++receiver.itemscount;
    },
    copyRow(receiver, index) {
      receiver.items.push(_.cloneDeep(receiver.items[index]));
      ++receiver.itemscount;
    },
    hideAddressesList(addressList) {
      setTimeout(() => {
        this[addressList] = false;
      }, 300);
    },
    checkForDifferencesCurrentVersion(
      currentOrder,
      oldVersionOrder,
      key1 = '',
      key2 = ''
    ) {
      const localOrder = {};
      const localOldOrder = {};

      for (const [key, value] of Object.entries(currentOrder)) {
        if (
          key === 'date' ||
          key === 'bills' ||
          key === 'invoices' ||
          key === 'oktmo' ||
          key === 'country' ||
          key === 'city'
        ) {
          continue;
        } else {
          if (
            currentOrder[key] &&
            typeof currentOrder[key] === 'object' &&
            !Array.isArray(currentOrder[key])
          ) {
            const data = this.checkForDifferencesCurrentVersion(
              currentOrder[key],
              oldVersionOrder[key],
              key,
              key
            );
            localOrder[key] = data[key + '_current'];
            localOldOrder[key] = data[key + '_old'];
          } else {
            localOrder[key] = value;
            localOldOrder[key] = oldVersionOrder[key];
          }
        }
      }
      return {
        [key1 + '_current']: localOrder,
        [key2 + '_old']: localOldOrder,
      };
    },
    save() {
      this.isValid = this.$refs.mainForm.validate();
      if (this.$refs.forms?.length) {
        this.$refs.forms.forEach((form) => {
          if (!form.validate()) {
            this.isValid = false;
          }
        });
      }
      if (!this.isValid) {
        return;
      }
      if (this.order.id) {
        const localOrder = JSON.parse(JSON.stringify(this.order));
        const oldVersionOrder = JSON.parse(JSON.stringify(this.oldVersions[0]));
        const { _current, _old } = this.checkForDifferencesCurrentVersion(
          localOrder,
          oldVersionOrder
        );
        if (!this._.isEqual(_current, _old)) {
          this.$store.commit('setOrder', localOrder);
          this.$emit('action', { action: 'save' });
        } else {
          this.$emit('action', { action: 'cancel' });
        }
      } else {
        this.$store.commit('setOrderToCreate', this.orders);
        this.$emit('action', { action: 'save', orders: true });
        this.receiversData = [
          {
            receiver: {
              id: '',
              name: '',
              type: '',
            },
            receiver_contact: {
              id: '',
              name: '',
            },
            receiver_address: {
              value: '',
              oktmo: '',
            },
            payer: {
              id: '',
              name: '',
              type: '',
            },
            status: {
              id: '',
              value: '',
            },
            summa: 0,
            itemscount: 0,
            items: [],
            urgency: 'Обычная',
            paymentType: {
              id: 'Безналичный',
              value: 'Безналичный',
            },
          },
        ];
        if(this.$refs.forms?.length) {
          this.$refs.forms.forEach((form) => {
            form.resetValidation();
          });
        }
        this.actor = '';
        this.currentPayer = '';
      }
    },
    create() {
      this.isCreationModalShow = true;
    },
    cancel() {
      this.receiversData = [
        {
          receiver: {
            id: '',
            name: '',
            type: '',
          },
          receiver_contact: {
            id: '',
            name: '',
          },
          receiver_address: {
            value: '',
            oktmo: '',
          },
          payer: {
            id: '',
            name: '',
            type: '',
          },
          status: {
            id: '',
            value: '',
          },
          summa: 0,
          itemscount: 0,
          items: [],
          urgency: 'Обычная',
          paymentType: {
            id: 'Безналичный',
            value: 'Безналичный',
          },
        },
      ];
      if(this.$refs.forms?.length) {
        this.$refs.forms.forEach((form) => {
          form.resetValidation();
        });
      }
      this.$emit('action', { action: 'cancel' });
      this.actor = '';
      this.currentPayer = '';
    },
    selectFromReference(field, isPayer = false, order) {
      this.$refs['refDialog'].isContacts = false;
      this.$refs['refDialog'].selectItem(
        this.order[field].id,
        (item) => {
          if (item) {
            switch (field) {
              case 'sender':
                this.$store.commit('setSender', item);
                break;
              case 'receiver':
                this.$store.commit('setReceiver', item);
                break;
              case 'payer':
                this.$store.commit('setPayer', item);
                break;
            }
            order[field] = {
              name: item.name,
              id: item.id,
              type: item.type,
            };
          }
        },
        isPayer
      );
      this.actor = field;
    },
    async getCurrentContact(field, currentField, order) {
      const id = order[field].id;
      if (!id) {
        this.currentContact = currentField;
        this.noOrgChosen = true;
        return;
      }
      await this.$store.dispatch('getClient', id);
      const contactData = this.$store.getters.currentClient.contacts;
      this.$refs['refDialog'].onselect = async (item) => {
        if (item) {
          order[currentField] = {
            name: item.surname + ' ' + item.name + ' ' + item.patronymic,
            id: item.id,
          };

          if (this.$store.state.clients.currentClient) {
            let contactAddress = item.contactadress;
            let receiverId = '';


            this.receiversData.forEach((receiver) => {
              this.$store.state.clients.currentClient.contacts.forEach((contact) => {
                if (contact.id === receiver.receiver_contact.id) {
                  receiverId = receiver.receiver.id;
                }
              });
            });

            if (receiverId) {
              const response = await daData.getData(contactAddress, daData.baseURLAddress);

              if (response.data?.suggestions?.length) {
                const address = response.data.suggestions[0];
                const receiverIndex = this.receiversData.findIndex((receiverData) => receiverData.receiver.id === receiverId);

                this.receiversData[receiverIndex].receiver_address = {
                  city: address.data.city,
                  country: address.data.country,
                  oktmo: address.data.oktmo,
                  value: address.value,
                };
              }
            }
          }
        }
      };
      this.$refs['refDialog'].updateItems(contactData, 'contacts');
      this.$refs['refDialog'].isContacts = true;
      this.actor = field;
    },
    checkForDifferences() {
      this.oldVersions.forEach((obj, index) => {
        const currentOrder = JSON.parse(JSON.stringify(obj));
        let emptyVersion = {};

        if (!this.oldVersions[index]) {
          return;
        }
        if (index === this.oldVersions.length - 1) {
          for (const key of Object.keys(currentOrder)) {
            if (key === 'items') {
              emptyVersion[key] = [];
            } else {
              emptyVersion[key] = '';
            }
          }
        }
        const olderOrder = this.oldVersions[index + 1]
          ? JSON.parse(JSON.stringify(this.oldVersions[index + 1]))
          : emptyVersion;

          this.compareVersions(olderOrder, currentOrder, index, null);
          this.differences = this.differences.filter((version)=> !!version);
      });
    },
    compareVersions(
      obj,
      currentObj,
      index,
      keyName = null,
      isArray = false,
      innerIndex = null
    ) {
      if (obj && Array.isArray(obj)) {
        if (obj.length >= currentObj.length) {
          obj.forEach((item, i) => {
            this.compareVersions(item, currentObj[i], index, keyName, true, i);
          });
        } else if (Array.isArray(currentObj)) {
          currentObj.forEach((item, i) => {
            this.compareVersions(obj[i], item, index, keyName, true, i);
          });
        }
        return;
      }

      for (let key of obj ? Object.keys(obj) : Object.keys(currentObj)) {
        const checkForValue = obj?.[key] ? obj?.[key] : currentObj?.[key];
        if (checkForValue && typeof checkForValue === 'object' && !isArray) {
          this.compareVersions(
            obj?.[key],
            currentObj?.[key],
            index,
            key,
            false,
            innerIndex
          );
        } else if (
          checkForValue &&
          typeof checkForValue === 'object' &&
          isArray
        ) {
          this.compareVersions(
            obj?.[key],
            currentObj?.[key],
            index,
            key,
            true,
            innerIndex
          );
        } else {
          if (obj?.[key] !== currentObj?.[key]) {
            let fakeKey = null;
            if (key === 'id' || keyName === 'contact' || key === 'number') {
              continue;
            }
            switch (key) {
              case 'date':
                fakeKey = 'Дата';
                break;

              case 'name':
                fakeKey = 'Имя';
                break;
              case 'description':
                fakeKey = 'Описание';
                break;
              case 'length':
                fakeKey = 'Длина';
                break;
              case 'height':
                fakeKey = 'Высота';
                break;
              case 'width':
                fakeKey = 'Ширина';
                break;
              case 'weight':
                fakeKey = 'Вес';
                break;
              case 'summa':
                fakeKey = 'Сумма';
                break;
              case 'itemscount':
                fakeKey = 'Количество грузов';
                break;
              case 'type':
                fakeKey = 'Тип организации';
                break;
              case 'urgency':
                fakeKey = 'Срочность';
                break;
              }
              let fakeKeyName = null;
              switch (keyName) {
                case 'box':
                  fakeKey = 'Коробка';
                  fakeKeyName = 'Грузы';
                  break;
                case 'bubbleWrap':
                  fakeKeyName = 'Грузы';
                  fakeKey = 'Воздушно-пузырьковая пленка';
                  break;
                case 'lathing':
                  fakeKeyName = 'Грузы';
                  fakeKey = 'Обрешетка';
                  break;
                case 'stretchFilm':
                  fakeKeyName = 'Грузы';
                  fakeKey = 'Стретч';
                  break;
                case 'status':
                fakeKey = 'Состояние';
                break;
              case 'cargoType':
                fakeKey = 'Тип груза';
                fakeKeyName = 'Грузы';
                break;
              case 'services':
                fakeKeyName = 'Грузы';
                break;
              case 'receiver_address':
                fakeKey = 'Адрес получателя';
                break;
              case 'sender_address':
                fakeKey = 'Адрес отправителя';
                break;
              case 'paymentVariant':
                fakeKey = 'Вариант оплаты';
                break;
              case 'paymentType':
                fakeKey = 'Тип оплаты';
                break;

              case 'sender_contact':
                fakeKeyName = 'Контактное лицо отправителя';
                break;
              case 'receiver_contact':
                fakeKeyName = 'Контактное лицо получателя';
                break;
              case 'receiver':
                fakeKeyName = 'Получатель';
                break;
              case 'sender':
                fakeKeyName = 'Отправитель';
                break;
              case 'items':
                fakeKeyName = 'Грузы';
                break;
              case 'contact':
                fakeKeyName = 'Контакт';
                break;
              case 'payer':
                fakeKeyName = 'Плательщик';
                break;
            }
            if (this.differences[index]) {
              if (isArray && fakeKeyName) {
                if (!this.differences[index][fakeKeyName]?.length) {
                  this.$set(this.differences[index], fakeKeyName, []);
                }
                if (!this.differences[index][fakeKeyName]?.[innerIndex]) {
                  this.$set(this.differences[index][fakeKeyName], innerIndex, {
                    [fakeKey]: obj?.[key]
                      ? {
                          was: obj[key],
                          become: currentObj?.[key] ? currentObj[key] : '-',
                        }
                      : {
                          was: '-',
                          become: currentObj?.[key],
                        },
                  });
                } else {
                  const addProps =
                    this.differences[index][fakeKeyName][innerIndex];
                  this.$set(this.differences[index][fakeKeyName], innerIndex, {
                    ...addProps,
                    [fakeKey]: obj?.[key]
                      ? {
                          was: obj[key],
                          become: currentObj?.[key] ? currentObj[key] : '-',
                        }
                      : {
                          was: '-',
                          become: currentObj?.[key],
                        },
                  });
                }

                continue;
              }
              if (fakeKeyName) {
                const addProps = this.differences[index][fakeKeyName];
                this.$set(this.differences[index], fakeKeyName, {
                  ...addProps,
                  [fakeKey]: obj?.[key]
                    ? {
                        was: obj[key],
                        become: currentObj?.[key] ? currentObj[key] : '-',
                      }
                    : {
                        was: '-',
                        become: currentObj?.[key],
                      },
                });
              } else {
                if (key === 'date') {
                  this.$set(
                    this.differences[index],
                    fakeKey,
                    currentObj?.[key]
                  );
                  continue;
                }
                this.$set(
                  this.differences[index],
                  fakeKey,
                  obj?.[key]
                    ? {
                        was: obj[key],
                        become: currentObj?.[key] ? currentObj[key] : '-',
                      }
                    : {
                        was: '-',
                        become: currentObj?.[key],
                      }
                );
              }
            } else {
              if (fakeKeyName) {
                this.$set(this.differences, index, {});
                this.$set(this.differences[index], fakeKeyName, {
                  [fakeKey]: obj?.[key]
                    ? {
                        was: obj[key],
                        become: currentObj?.[key] ? currentObj[key] : '-',
                      }
                    : {
                        was: '-',
                        become: currentObj?.[key],
                      },
                });
              } else {
                if (key === 'date') {
                  this.$set(this.differences, index, {
                    [fakeKey]: currentObj?.[key],
                  });
                  continue;
                }
                this.$set(this.differences, index, {
                  [fakeKey]: obj?.[key]
                    ? {
                        was: obj[key],
                        become: currentObj?.[key] ? currentObj[key] : '-',
                      }
                    : {
                        was: '-',
                        become: currentObj?.[key],
                      },
                });
              }
            }
          }
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.receiver {
  &__list {
    list-style: none;
    padding: 0;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}
.order-form {
  overflow: hidden;
  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    padding: 10px;
    left: 0;
    top: 40px;
    height: 200px;
    min-height: 150px;
    min-width: 300px;
    overflow-y: auto;
  }
  &__wrapper {
    position: relative;
  }
}
.address-list {
  z-index: 100;
  width: 100%;
  &__header {
    padding: 0;
    margin-bottom: 20px;
  }
  &__progress {
  }
  &__list {
    width: 100%;
  }
  &__item {
    width: 100%;
  }
}

.no-org-chosen {
  &__title {
  }
  &__card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  &__btn {
  }
}

.processing {
  &__card {
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__process {
  }
}
.over-version {
  align-items: flex-start;
}
.order-history {
  &__item {
    min-height: auto;
  }
  &__list {
    max-width: 1200px;
    overflow: auto;
    max-height: 800px;
  }
  &__title {
    margin-top: 10px;
    font-size: 18px;
    font-weight: 600;
  }
}
.inner-list {
  &__title {
    font-size: 18px;
    font-weight: 700;
  }
}

.was {
  color: red;
}

.become {
  color: green;
}

.state {
  font-weight: 700;
}

.number-of-cargo {
  font-weight: 600;
}
.no-changes {
  padding: 15px;
  background-color: #fff;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
}

.close-button {
  position: absolute;
  right: 16px;
  top: 16px;
  width: 24px;
  height: 24px;
  opacity: 0.6;
  font-size: 0;
}
.close-button:hover {
  opacity: 1;
}
.close-button:before, .close-button:after {
  position: absolute;
  left: 12px;
  top: 0;
  content: ' ';
  height: 24px;
  width: 1px;
  background-color: #333;
}
.close-button:before {
  transform: rotate(45deg);
}
.close-button:after {
  transform: rotate(-45deg);
}

</style>

<template>
  <v-layout>
    <v-dialog v-model="isActive" class="contact-creation" width="40%">
      <v-card class="contact-creation__card">
        <v-card-title class="contact-creation__title">
          Создание контакта
        </v-card-title>
        <v-form
          ref="form"
          v-model="isValid"
          class="contact-creation__form"
          @submit.prevent="submit"
        >
          <v-text-field
            v-model="contact.surname"
            label="Фамилия"
            required
            :rules="noEmptyFields"
          />
          <v-text-field
            v-model="contact.name"
            label="Имя"
            required
            :rules="noEmptyFields"
          />
          <v-text-field v-model="contact.patronymic" label="Отчество" />
          <v-menu
            ref="menu"
            v-model="dateMenu"
            :close-on-content-click="false"
            :return-value.sync="contact.dateofbirth"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="contact.dateofbirth"
                label="Дата рождения"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker v-model="contact.dateofbirth" no-title scrollable>
              <v-spacer />
              <v-btn text color="primary" @click="dateMenu = false">
                Отмена
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.menu.save(contact.dateofbirth)"
              >
                ОК
              </v-btn>
            </v-date-picker>
          </v-menu>
          <v-text-field
            v-if="$store.state.clients.currentClient.type === 'ЮридическоеЛицо'"
            v-model="contact.jobtitle"
            label="Должность"
          />
          <v-text-field v-model="contact.contactadress" label="Адрес" />
          <v-text-field-simplemask
            v-if="$store.state.clients.currentClient.type === 'ЮридическоеЛицо'"
            v-model="contact.contactworkphone"
            :label="'Рабочий телефон'"
            :properties="{
              readonly: false,
              disabled: false,
              outlined: false,
              clearable: true,
              placeholder: 'Введите рабочий телефон телефон',
            }"
            :options="{
              humanMask: '+# (###) ###-##-##',
              machineMask: '###########',
              empty: null,
              applyAfter: false,
              alphanumeric: true,
              lowerCase: false,
            }"
          />
          <v-text-field-simplemask
            v-model="contact.contactcellphone"
            :label="'Мобильный телефон'"
            :properties="{
              readonly: false,
              disabled: false,
              outlined: false,
              clearable: true,
              placeholder: 'Введите мобильный телефон',
            }"
            :options="{
              humanMask: '+# (###) ###-##-##',
              machineMask: '############',
              empty: null,
              applyAfter: false,
              alphanumeric: true,
              lowerCase: false,
            }"
          />
          <v-text-field
            v-model="contact.contactemail"
            label="Электронная почта"
          />
          <v-textarea v-model="contact.description" label="Описание" />
          <div class="mt-2 contact-creation__btn-wrapper">
            <v-btn type="submit" color="primary" class="mr-2">
              Сохранить
            </v-btn>
            <v-btn @click="clearData(contact)"> Отменить </v-btn>
          </div>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showStatus" width="30%" class="creation-status">
      <v-card class="creation-status__card">
        <v-progress-circular
          v-if="isLoading"
          class="creation-status__progress"
          size="50"
          width="6"
          indeterminate
          color="primary"
        />
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import SimpleMask from '@/components/SimpleMask.vue';
export default {
  components: {
    'v-text-field-simplemask': SimpleMask,
  },
  props: {
    isShowing: {
      type: Boolean,
      required: true,
    },
    clientId: {
      type: String,
      required: false,
    },
    contactsData: {
      type: [Object, String],
      required: false,
    },
  },
  data() {
    return {
      isValid: true,
      dateMenu: false,
      showStatus: false,
      noEmptyFields: [(v) => !!v || 'Поле обязательно для заполнения'],
      clearData(obj) {
        for (const key of Object.keys(obj)) {
          if (key === 'dateofbirth') {
            obj[key] = new Date()
              .toLocaleDateString()
              .split('.')
              .reverse()
              .join('-');
          } else {
            obj[key] = '';
          }
        }
        this.$refs.form.resetValidation();
        this.$emit('clearData', this.contact);
        this.isActive = false;
      },
      // eslint-disable-next-line
      emailValidation: [(v)=> (/^([\d\w\.-]+@[A-Za-z]+\.[A-Za-z]+)$/).test(v)  || "Введите корректный email"],
      contact: {
        id: '',
        name: '',
        patronymic: '',
        surname: '',
        dateofbirth: new Date()
          .toLocaleDateString()
          .split('.')
          .reverse()
          .join('-'),
        jobtitle: '',
        description: '',
        contactadress: '',
        contactworkphone: '',
        contactcellphone: '',
        contactemail: '',
      },
      isLoading: false,
    };
  },
  computed: {
    isActive: {
      get() {
        return this.isShowing;
      },
      set(value) {
        this.$emit('update:isShowing', value);
      },
    },
    compContacts() {
      if (this.contact.id) {
        const editedContact = {
          parentid: this.clientId,
        };
        for (const [key, value] of Object.entries(this.contact)) {
          if (key === 'id') {
            continue;
          } else {
            editedContact[key] = value;
          }
        }
        return editedContact;
      } else {
        return { ...this.contact, parentid: this.clientId };
      }
    },
  },
  watch: {
    contactsData: async function (value) {
      if (value.id) {
        this.contact.id = value.id;
        this.contact.name = value.name;
        this.contact.patronymic = value.patronymic;
        this.contact.surname = value.surname;
        this.contact.dateofbirth = value.dateofbirth;
        this.contact.jobtitle = value.jobtitle;
        this.contact.description = value.description;
        this.contact.contactadress = value.contactadress;
        this.contact.contactworkphone = value.contactworkphone;
        this.contact.contactcellphone = value.contactcellphone;
        this.contact.contactemail = value.contactemail;
      }
    },
  },
  methods: {
    async submit() {
      this.isValid = this.$refs.form.validate();
      if (!this.isValid) {
        return;
      }
      this.showStatus = true;
      this.isLoading = true;
      try {
        if (!this.contact.id) {
          await this.$store.dispatch('createContact', this.compContacts);
        } else {
          await this.$store.dispatch('patchContact', {
            id: this.contact.id,
            data: this.compContacts,
          });
        }
        this.clearData(this.contact);
      } catch (error) {
        this.showAlert(error);
      } finally {
        this.showStatus = false;
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-creation {
  &__card {
    padding: 20px;
  }
}

.creation-status {
  &__card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  &__title {
    text-align: center;
  }
  &__btn {
  }
  &__progress {
    margin: 10px 0;
  }
}
</style>

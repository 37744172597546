<template>
  <v-layout>
    <v-dialog v-model="visible" scrollable width="70%">
      <v-card  class="reference-card">
        <v-card-title> Выберите значение </v-card-title>
        <v-card-text style="max-height: 700px" class="pt-5">
          <v-layout class="mb-4">
            <v-btn v-if="!isContacts" color="primary" @click="onCreate">
              Создать
            </v-btn>
            <v-btn v-if="isContacts" color="primary" @click="createContacts">
              Создать
            </v-btn>
          </v-layout>
          <v-data-table
            :headers="headers"
            :items="items"
            :item-class="itemClasses"
            :loading="loading"
            item-key="id"
            :options.sync="options"
            class="elevation-1 data-table"
            hide-default-footer
            :search="search"
            no-data-text="Записей не найдено"
            loading-text="Загрузка..."
            @dblclick:row="selectRow(arguments[1].item)"
            @click:row="selected = arguments[1].item"
            @contextmenu:row="contextMenuShow(arguments[0], arguments[1].item)"
          >
            <template v-slot:item.$context="{ item }">
              <v-hover v-slot="{ hover }" class="mr-3">
                <v-icon
                  :color="hover ? '#000' : '#666'"
                  @click.stop="contextMenuShow($event, item)"
                >
                  mdi-menu
                </v-icon>
              </v-hover>
            </template>
          </v-data-table>
          <div class="d-flex justify-end pt-2">
            <span class="pt-2 mr-3">Строк на странице:</span>
            <div style="width: 100px" class="mr-3">
              <v-select
                v-model="options.itemsPerPage"
                dense
                solo
                :items="itemsPerPageVariants"
              />
            </div>
            <v-pagination
              v-model="options.page"
              :length="calcPages"
              :total-visible="7"
            />
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-4">
          <v-spacer />
          <v-btn color="primary" @click="select"> Выбрать </v-btn>
          <v-btn @click="cancel"> Отмена </v-btn>
        </v-card-actions>
      <v-menu
        v-model="contextMenuVisible"
        :position-x="x"
        :position-y="y"
        absolute
        offset-y
      >
        <v-list>
          <v-list-item
            v-for="(item, index) in contextMenuItems"
            :key="index"
            @click="contextMenuClick(item.action, isContacts)"
          >
            <v-list-item-title>{{ item.caption }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      </v-card>
    </v-dialog>
    <v-dialog v-model="noAccount" class="noAccount" width="50%">
      <v-card class="noAccount__card">
        <v-card-title class="noAccount__title mb-5">
          У плательщика не указаны платежные данные! Пожалуйста, укажите другого
          плательщика либо внесите платежные данные плательщика
        </v-card-title>
        <div class="noAccount__btn_wrapper">
          <v-btn
            color="mr-4 primary"
            @click="
              $emit('action', $store.getters.currentClient);
              noAccount = false;
            "
          >
            Внести данные
          </v-btn>
          <v-btn @click="noAccount = false"> Отмена </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>
export default {
  data() {
    return {
      visible: false,
      items: [],
      reference: '',
      isPayer: false,
      onselect: '',
      noAccount: '',
      contextMenuVisible: false,
      isContacts: false,
      x: 0,
      y: 0,
      contextMenuItems: [
        {
          caption: 'Изменить',
          action: 'edit',
        },
        {
          caption: 'Удалить',
          action: 'delete',
        },
      ],
      headers: [
        {
          text: '',
          width: '50px',
          value: '$context',
          sortable: false,
        },
        { text: 'Наименование', value: 'name' },
      ],
      search: '',
      itemsPerPage: 10,
      loading: false,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      itemsPerPageVariants: [5, 10, 50, 100],
      selected: '',
    };
  },
  computed: {
    calcPages() {
      return this.items?.length
        ? Math.ceil(
            this.items.length /
              (this.options.itemsPerPage == 0 ? 1 : this.options.itemsPerPage)
          )
        : 1;
    },
  },
  methods: {
    contextMenuShow(e, item) {
      e.preventDefault();
      this.contextMenuItem = item;
      this.contextMenuVisible = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.contextMenuVisible = true;
      });
    },
    itemClasses(item) {
      if (item.id == (this.selected != '' ? this.selected?.id : ''))
        return 'selectedRowClass';
    },
    selectItem(value, onselect, isPayer = false) {
      this.headers = [
        {
          text: '',
          width: '50px',
          value: '$context',
          sortable: false,
        },
        { text: 'Наименование', value: 'name' },
      ];
      this.items = [];
      this.onselect = onselect;
      this.visible = true;
      this.isPayer = isPayer;
      this.loadValues(value);
    },
    contextMenuClick(action, isContacts = false) {
      this.$emit('action', {
        action: action,
        item: this.contextMenuItem,
        isContacts,
      });
    },
    updateItems(value, type = 'org') {
      if (type !== 'org') {
        this.headers = [
          {
            text: '',
            value: '$context',
            sortable: false,
          },
          { text: 'Фамилия', value: 'surname' },
          { text: 'Имя', value: 'name' },
          { text: 'Отчество', value: 'patronymic' },
          {
            text: 'Контактный телефон',
            value: 'contactcellphone' || 'contactworkphone',
          },
          { text: 'email', value: 'contactemail' },
        ];
      }
      this.items = value;
      this.visible = true;
    },
    async loadValues(value) {
      this.loading = true;
      try {
        await this.$store.dispatch('uploadClients');
        this.items = this.$store.getters.currentClients;
      } catch (error) {
        this.showAlert(error);
      } finally {
        this.loading = false;
      }
      if (value) {
        this.selected = this.items.find((element) => {
          return element.id == value;
        });
      } else {
        this.selected = '';
      }
    },
    selectRow(row) {
      this.selected = row;
      this.select();
    },
    async select() {
      if (this.isPayer) {
        await this.$store.dispatch('getClient', this.selected.id);
        const currentClient = this.$store.getters.currentClient;
        if (!currentClient.bankAccount) {
          this.noAccount = true;
          return;
        }
      }
      this.onselect(this.selected);
      this.selected = '';
      this.visible = false;
    },
    cancel() {
      this.visible = false;
    },
    createContacts() {
      this.visible = false;
      this.$emit('contacts');
    },
    onCreate() {
      this.visible = false;
      this.$emit('create');
    },
  },
};
</script>
<style lang="scss" scoped>
.selectedRowClass {
  background: #ddddff;
}

.reference-card {
  width: 100%;
}
.noAccount {
  &__card {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__title {
    text-align: center;
  }
}
</style>

<template>
  <section class="pa-4">
    <v-row class="mb-4">
      <v-btn color="primary" :disabled="loading" @click="createOrder"> Создать накладную </v-btn>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      item-key="id"
      :options.sync="options"
      class="elevation-1 data-table"
      hide-default-footer
      :search="search"
      no-data-text="Не найдено накладных"
      loading-text="Загрузка..."
      @dblclick:row="editOrder(arguments[1].item)"
    >
      <template v-if="isMobile" v-slot:body.prepend>
        <tr class="no-wrap">
          <td>
            <v-text-field
              v-model="number"
              type="text"
              label="Поиск по номеру"
            />
          </td>
          <td>
            <v-layout class="d-flex">
              <v-menu
                ref="dateFromMenu"
                v-model="dateFromMenu"
                class="order__date-menu"
                :close-on-content-click="false"
                :return-value.sync="dateFrom"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="mr-2 order__date-input"
                    :value="compDateFrom"
                    label="C"
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    readonly
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="dateFrom"
                  no-title
                  scrollable
                  :first-day-of-week="1"
                  locale="ru-RU"
                >
                  <v-spacer />
                  <v-btn text color="primary" @click="dateFromMenu = false">
                    Отмена
                  </v-btn>
                  <v-btn text color="primary" @click="dateFrom = ''">
                    Очистить
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dateFromMenu.save(dateFrom)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
              <v-menu
                ref="dateToMenu"
                v-model="dateToMenu"
                class="order__date-menu"
                :close-on-content-click="false"
                :return-value.sync="dateTo"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    prepend-icon="mdi-calendar"
                    class="order__date-input"
                    :value="compDateTo"
                    label="По"
                    v-bind="attrs"
                    readonly
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="dateTo"
                  no-title
                  scrollable
                  :first-day-of-week="1"
                  locale="ru-RU"
                >
                  <v-spacer />
                  <v-btn text color="primary" @click="dateToMenu = false">
                    Отмена
                  </v-btn>
                  <v-btn text color="primary" @click="dateTo = ''">
                    Очистить
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dateToMenu.save(dateTo)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-layout>
          </td>
          <td>
            <v-text-field
              v-model="sender"
              type="text"
              label="Поиск по отправителю"
            />
          </td>
          <td>
            <v-text-field
              v-model="receiver"
              type="text"
              label="Поиск по получателю"
            />
          </td>
          <td>
            <v-layout class="d-flex">
              <v-text-field
                v-model="sumFrom"
                class="mr-2 order__number-input"
                type="number"
                label="От"
              />
              <v-text-field
                v-model="sumTo"
                class="order__number-input"
                type="number"
                label="До"
              />
            </v-layout>
          </td>
          <td>
            <v-select
              v-model="selectedStatuses"
              :items="statuses"
              multiple
              label="Фильтр по статусу"
            />
          </td>
          <td ref="client">
            <v-text-field
              v-model="client"
              class="order__client-input"
              type="text"
              label="Поиск по клиенту"
            />
          </td>
        </tr>
      </template>
    </v-data-table>
    <div class="d-flex justify-end pt-2">
      <span class="pt-2 mr-3">Строк на странице:</span>
      <div style="width: 100px" class="mr-3">
        <v-select
          v-model="options.itemsPerPage"
          dense
          solo
          :items="itemsPerPageVariants"
        />
      </div>
      <v-pagination
        v-model="options.page"
        :length="paginaion"
        :total-visible="7"
      />
    </div>
    <v-dialog v-model="orderFormVisible" width="80%">
      <order-form v-model="valid" @action="onaction" :loading="loading" />
    </v-dialog>
  </section>
</template>

<script>
import OrderForm from '@/components/OrderForm';
import formatStringToUpperCase from '@/helpers/formatStringToUpperCase';

export default {
  components: {
    OrderForm,
  },
  data() {
    return {
      dateToMenu: false,
      dateFromMenu: false,
      dateFrom: '',
      dateTo: '',
      orderFormVisible: false,
      clients: [],
      valid: false,
      items: [],
      number: '',
      client: '',
      sender: '',
      receiver: '',
      sumFrom: '',
      sumTo: '',
      statuses: ['Новый', 'Принято', 'Прибыло в офис отправления', 'Прибыл в город получателя', 'Отправлен', 'На доставке', 'Выполнен', 'Отменен'],
      selectedStatuses: [],
      headers: [
        {
          text: 'Номер',
          align: 'start',
          value: 'number',
          filter: (value) => {
            if (!this.number.trim()) return true;
            return value.startsWith(this.number.trim());
          },
        },
        {
          text: 'Дата',
          value: 'date',
          filter: (value) => {
            const localValue = value.split('.').reverse().join('-');
            if (!this.dateFrom && !this.dateTo) return true;
            else if (!this.dateFrom) {
              return (
                new Date(this.dateTo).getTime() >=
                new Date(localValue).getTime()
              );
            } else if (!this.dateTo) {
              return (
                new Date(this.dateFrom).getTime() <=
                new Date(localValue).getTime()
              );
            } else {
              return (
                new Date(this.dateFrom).getTime() <=
                  new Date(localValue).getTime() &&
                new Date(this.dateTo).getTime() >=
                  new Date(localValue).getTime()
              );
            }
          },
        },
        {
          text: 'Отправитель',
          value: 'sender',
          filter: (value) => {
            if (!this.sender.trim()) return true;
            return value
              .toUpperCase()
              .includes(this.formatStringToUpperCase(this.sender));
          },
        },
        {
          text: 'Получатель',
          value: 'receiver',
          filter: (value) => {
            if (!this.receiver.trim()) return true;
            return value
              .toUpperCase()
              .includes(this.formatStringToUpperCase(this.receiver));
          },
        },
        {
          text: 'Сумма',
          value: 'summa',
          filter: (value) => {
            if (!this.sumFrom && !this.sumTo) return true;
            if (!this.sumFrom) {
              return +value <= +this.sumTo;
            } else if (!this.sumTo) {
              return +value >= +this.sumFrom;
            } else {
              return +value <= +this.sumTo && +value >= +this.sumFrom;
            }
          },
        },
        {
          text: 'Состояние',
          value: 'status.value',
          width: '300px',
          filter: (value) => {
            if (!this.selectedStatuses.length) return true;
            return this.selectedStatuses.includes(value);
          },
        },
        {
          text: 'Клиент',
          value: 'counterparty.name',
          width: '300px',
          filter: (value) => {
            if (!this.client.trim()) return true;
            return value
              .toUpperCase()
              .includes(this.formatStringToUpperCase(this.client));
          },
        },
      ],
      search: '',
      itemsPerPage: 10,
      loading: false,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      itemsPerPageVariants: [5, 10, 50, 100],
    };
  },
  computed: {
    isMobile() {
      return document.documentElement.clientWidth > 1023 ? true : false;
    },
    compDateFrom() {
      return this.dateFrom.split('-').reverse().join('.');
    },
    compDateTo() {
      return this.dateTo.split('-').reverse().join('.');
    },
    paginaion() {
      return this.items?.length
        ? Math.ceil(
            this.items.length /
              (this.items.length == 0 ? 1 : this.options.itemsPerPage)
          )
        : 1;
    },
  },
  async mounted() {
    this.loading = true;
    try {
      const response = await this.$store.dispatch('loadOrders');
      this.items = response.items;
    } catch (e) {
      this.showAlert(e);
    } finally {
      this.loading = false;
    }
    if (this.$route.params?.openOrderModal) {
      this.orderFormVisible = true;
    }
    if(this.$store.state.user.role !== 'Администратор') {
      this.headers = this.headers.filter((header)=> header.text !== 'Клиент');
      this.$refs.client.remove();
    }
  },
  async created() {
    await this.$store.dispatch('uploadClients');
  },
  methods: {
    formatStringToUpperCase,
    createOrder() {
      this.orderFormVisible = true;
    },
    async editOrder(row) {
      try {
        await this.$store.dispatch('getOrder', row.id);
        await this.$store.dispatch('getOrderHistory', row.id);

        this.orderFormVisible = true;
      } catch (e) {
        this.showAlert(e);
      }
    },
    async onaction(action) {
      if(action.action == 'show') {
         this.orderFormVisible = true;
        return;
      }
      if(action.action == 'hide') {
         this.orderFormVisible = false;
        return;
      }
      this.loading = true;
      try {
        if (action.action == 'save') {
          if (!this.$store.state.orders.order.id) {
            if (action.orders) {
              await this.$store.dispatch('createOrders');
            } else {
              await this.$store.dispatch('createOrder');
            }
          } else {
            await this.$store.dispatch('patchOrder', {
              id: this.$store.state.orders.order.id,
              data: this.$store.getters.currentOrder,
            });
          }
        }
        this.$store.commit('resetOrdersToCreate');
        this.$store.commit('resetOrder');
        this.orderFormVisible = false;
        await this.$store.dispatch('loadOrders');
        this.items = this.$store.getters.currentOrderList;
      } catch (error) {
        this.showAlert(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.data-table th {
  background: #eee;
  font-weight: bold;
}
.data-table tr {
  cursor: pointer;
}

.no-wrap {
  white-space: nowrap;
}

.order {
  &__date-input {
    max-width: 150px !important;
  }
  &__number-input {
    max-width: 120px !important;
  }
}
</style>

import Axios from 'axios';

export default {
  baseURLData:
    'https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party',
  baseURLAddress:
    'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address',
  async getData(value, url) {
    const response = await Axios.post(
      url,
      { query: value },
      {
        mode: 'no-cors',
        headers: {
          Authorization: 'Token ' + '60b6290676a632456f55091e158ae6e20a230d99',
        },
        withCredentials: false,
      }
    );
    return response;
  },
};
